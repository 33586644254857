import React, { useState, useEffect } from "react";
import { FaUser, FaEdit, FaSave, FaCamera } from "react-icons/fa";
import userService from "../connections/user";

const UserProfile = () => {
  const [user, setUser] = useState({
    id: "",
    firstName: "",
    lastName: "",
    address: "",
    description: "",
    phone: "",
    email: "",
    profileImage: null,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const loadUserData = () => {
      try {
        setIsLoading(true);
        // First try to get user data from localStorage
        const savedUser = JSON.parse(localStorage.getItem("user") || "{}");
        console.log("Saved user from localStorage:", savedUser.id); // Log the saved user for debugging
        if (savedUser && Object.keys(savedUser).length > 0) {
          setUser({
            id: savedUser.id || "",
            firstName: savedUser.firstName || "",
            lastName: savedUser.lastName || "",
            address: savedUser.address || "",
            description: savedUser.description || "",
            phone: savedUser.phone || "",
            email: savedUser.email || "",
            profileImage: savedUser.profileImage || null,
          });

          if (savedUser.profileImage) {
            setImagePreview(savedUser.profileImage);
          }
        } else {
          // If no localStorage data, fetch from API
          fetchUserData();
        }
        setIsLoading(false);
      } catch (err) {
        console.error("Error loading user data:", err);
        setError("Failed to load user data");
        setIsLoading(false);
      }
    };

    const fetchUserData = async () => {
      try {
        const userData = await userService.getUser();
        setUser({
          id: userData.id || "",
          firstName: userData.firstName || "",
          lastName: userData.lastName || "",
          address: userData.address || "",
          description: userData.description || "",
          phone: userData.phone || "",
          email: userData.email || "",
          profileImage: userData.profileImage || null,
        });

        if (userData.profileImage) {
          setImagePreview(userData.profileImage);
        }
        // Save to localStorage for future use
        localStorage.setItem("user", JSON.stringify(userData));
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Failed to fetch user data");
      }
    };

    loadUserData();
  }, []);

  const saveUserData = async () => {
    try {
      setIsLoading(true);

      // Create FormData object
      const formData = new FormData();

      // Append all user data to formData
      formData.append("userId", user.id);
      formData.append("firstName", user.firstName);
      formData.append("lastName", user.lastName);
      formData.append("address", user.address);
      formData.append("description", user.description);
      formData.append("phone", user.phone);
      formData.append("email", user.email);

      // If a new image was selected, append it
      if (imageFile) {
        formData.append("profileImage", imageFile);
      }

      // Call API to update user with FormData
      const updatedUser = await userService.updateUser(formData);

      // Update localStorage with the new data
      const currentUserData = JSON.parse(localStorage.getItem("user") || {});
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...currentUserData, // Keep all existing data including _id
          ...updatedUser, // Apply updates
          _id: currentUserData._id, // Explicitly preserve the _id
        })
      );

      setIsEditing(false);
      setIsLoading(false);

      // Update state with the returned data (especially important for image URL)
      setUser((prev) => ({
        ...prev,
        ...updatedUser,
      }));
      // Log the updated user for debugging
      if (currentUserData.profileImage) {
        setImagePreview(currentUserData.profileImage);
        window.location.reload();
      }
    } catch (error) {
      setError("Failed to save profile changes");
      setIsLoading(false);
      console.error("Error saving user data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file); // Store the file object for later upload

      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Rest of your component remains the same...
  return (
    <div className="container py-5">
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
          <button
            type="button"
            className="btn-close float-end"
            onClick={() => setError(null)}
          ></button>
        </div>
      )}

      <div className="row">
        <div className="col-lg-4">
          <div className="card mb-4">
            <div className="card-body text-center">
              <div className="position-relative d-inline-block mb-3">
                {imagePreview ? (
                  <img
                    src={imagePreview?.url || imagePreview}
                    alt="Profile"
                    className="rounded-circle img-fluid"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div
                    className="rounded-circle bg-secondary d-flex align-items-center justify-content-center"
                    style={{ width: "150px", height: "150px" }}
                  >
                    <FaUser size={60} color="white" />
                  </div>
                )}
                {isEditing && (
                  <div className="position-absolute bottom-0 end-0">
                    <label
                      htmlFor="profileImageInput"
                      className="btn btn-sm btn-primary rounded-circle"
                    >
                      <FaCamera />
                      <input
                        id="profileImageInput"
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        style={{ display: "none" }}
                      />
                    </label>
                  </div>
                )}
              </div>
              <h5 className="my-3">
                {user.firstName || user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : "Your Name"}
              </h5>
              <p className="text-muted mb-1">
                {user.description || "No description added"}
              </p>
              <div className="d-flex justify-content-center mb-2">
                {isEditing ? (
                  <button
                    className="btn btn-primary"
                    onClick={saveUserData}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <FaSave className="me-2" />
                    )}
                    Save Profile
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => setIsEditing(true)}
                  >
                    <FaEdit className="me-2" /> Edit Profile
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-8">
          <div className="card mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">First Name</p>
                </div>
                <div className="col-sm-9">
                  {isEditing ? (
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={user.firstName}
                      onChange={handleChange}
                      placeholder="Enter first name"
                    />
                  ) : (
                    <p className="text-muted mb-0">
                      {user.firstName || "Not specified"}
                    </p>
                  )}
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">Last Name</p>
                </div>
                <div className="col-sm-9">
                  {isEditing ? (
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={user.lastName}
                      onChange={handleChange}
                      placeholder="Enter last name"
                    />
                  ) : (
                    <p className="text-muted mb-0">
                      {user.lastName || "Not specified"}
                    </p>
                  )}
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">Email</p>
                </div>
                <div className="col-sm-9">
                  {isEditing ? (
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                      placeholder="Enter email"
                    />
                  ) : (
                    <p className="text-muted mb-0">
                      {user.email || "Not specified"}
                    </p>
                  )}
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">Phone</p>
                </div>
                <div className="col-sm-9">
                  {isEditing ? (
                    <input
                      type="tel"
                      className="form-control"
                      name="phone"
                      value={user.phone}
                      onChange={handleChange}
                      placeholder="Enter phone number"
                    />
                  ) : (
                    <p className="text-muted mb-0">
                      {user.phone || "Not specified"}
                    </p>
                  )}
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">Address</p>
                </div>
                <div className="col-sm-9">
                  {isEditing ? (
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      value={user.address}
                      onChange={handleChange}
                      placeholder="Enter address"
                    />
                  ) : (
                    <p className="text-muted mb-0">
                      {user.address || "Not specified"}
                    </p>
                  )}
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">Description</p>
                </div>
                <div className="col-sm-9">
                  {isEditing ? (
                    <textarea
                      className="form-control"
                      name="description"
                      value={user.description}
                      onChange={handleChange}
                      placeholder="Enter description"
                      rows="3"
                    />
                  ) : (
                    <p className="text-muted mb-0">
                      {user.description || "Not specified"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
