import React from 'react';
import Navbar from '../components/navbars/Navbar.js';
import Trending from '../components/hompageComp/Trending';
import Services from '../components/hompageComp/Services.js';
import Header from '../components/hompageComp/Header';
import Footer from '../components/hompageComp/Footer';
import TopFreelancers from '../components/hompageComp/Topfreelancers.js';
import TrendProducts from '../components/hompageComp/TrendProducts.js';
import SubNavbar from '../components/navbars/SubNavbar.js';
const Homepage = () => {
  return (
    <div>
      <Navbar/>
      <SubNavbar/>
      <Header/>
      <Services/>
       <TopFreelancers/>
       <TrendProducts/>
       <Trending/>
       <Footer/>

    </div>
  );
};

export default Homepage;