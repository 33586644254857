import api from './connect';

const userService = {
  // Fetch user data
  getUser: async (userId) => {
    try {
      const response = await api.get(`/users/getUser`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user:', error);
      throw error;
    }
  },

  // Create new user
  createUser: async (userData) => {
    try {
      console.log('User data:', userData); // Log the user data
      const response = await api.post('/users/register', userData);
      return response.data;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  },
// Update user data with FormData
updateUser: async (formData) => {
  try {
     // Log the FormData for debugging
    const response = await api.put('/users/updateUser', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
},

  // Login user
  login: async (credentials) => {
    try {
      const response = await api.post('/users/login', credentials);
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },
  logout: async (token) => {
    try {
        const response = await api.post('/users/logout', {}, { 
            headers: { Authorization: `Bearer ${token}` } 
        });
        return response.data;
    } catch (error) {
        console.error('Logout error:', error);
        throw error;
    }
}

};

export default userService;