import React, { useState, useEffect } from "react";

const categories = [
    { name: "Freelancers", icon: "💼", href: "/freelancers" },
    { name: "Books", icon: "📚", href: "/books" },
    { name: "Food", icon: "🍔", href: "/food" },
    { name: "Instruments", icon: "🎸", href: "/instruments" },
    { name: "Electronics", icon: "💻", href: "/electronics" },
    { name: "Study Tools", icon: "📝", href: "/study-tools" },
    { name: "Jobs", icon: "🧑‍💼", href: "/jobs" },
    { name: "Fashion & Beauty", icon: "👕", href: "/fashion-beauty" },
    { name: "Vehicles", icon: "🚗", href: "/vehicles" },
    { name: "Others", icon: "🔍", href: "/others" },
  ];
  

const CategoryMenu = () => {
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine if we should use two rows (for screens smaller than 768px)
  const useTwoRows = windowWidth < 768;
  
  // Split categories into two rows if needed
  const firstRow = useTwoRows ? categories.slice(0, Math.ceil(categories.length / 2)) : categories;
  const secondRow = useTwoRows ? categories.slice(Math.ceil(categories.length / 2)) : [];

  return (
    <nav 
      className="bg-dark w-100" 
      style={{ 
        position: "relative",
        top: "100px",
        marginTop: "56px",
        zIndex: 1020,
        height: useTwoRows ? "120px" : "60px",
      }}
    >
      <div className="d-flex flex-column h-100">
        {/* First row */}
        <div className="d-flex align-items-center justify-content-between flex-grow-1 container-fluid px-2">
          {firstRow.map((category, index) => (
            <div
              key={index}
              className="h-100 flex-grow-1 text-center"
              style={{ flex: "1 1 0", minWidth: useTwoRows ? "60px" : "80px" }}
            >
              <a 
                href={category.href} 
                className="d-flex flex-column align-items-center text-white text-decoration-none h-100 justify-content-center"
              >
                <span className="fs-5 mb-1">{category.icon}</span>
                <span className="small" style={{ whiteSpace: "nowrap", fontSize: useTwoRows ? "0.7rem" : "0.875rem" }}>
                  {category.name}
                </span>
              </a>
            </div>
          ))}
        </div>

        {/* Second row (only displayed on small screens) */}
        {useTwoRows && secondRow.length > 0 && (
          <div className="d-flex align-items-center justify-content-between flex-grow-1 container-fluid px-2">
            {secondRow.map((category, index) => (
              <div
                key={index}
                className="h-100 flex-grow-1 text-center"
                style={{ flex: "1 1 0", minWidth: "60px" }}
              >
                <a 
                  href={category.href} 
                  className="d-flex flex-column align-items-center text-white text-decoration-none h-100 justify-content-center"
                >
                  <span className="fs-5 mb-1">{category.icon}</span>
                  <span className="small" style={{ whiteSpace: "nowrap", fontSize: "0.7rem" }}>
                    {category.name}
                  </span>
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </nav>
  );
};

export default CategoryMenu;