import React from "react";
import image1 from "../assets/img/posters/p2.png";
import image4 from "../assets/img/posters/p3.png";
import image6 from "../assets/img/posters/p4.png";

function Trending() {
  return (
    <section className="clean-block slider bg-dark">
      <div className="container">
        <div className="block-heading">
          <h2 className="text-info">Advertisement</h2>
        </div>
        <div className="carousel slide" data-bs-ride="carousel" id="carousel-1">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="trendimg"
                src={image1}
                alt="Trending slide showing [brief description]"
              />
            </div>
            <div className="carousel-item">
              <img
                className="trendimg"
                src={image4}
                alt="Another trending slide displaying [brief description]"
              />
            </div>
            <div className="carousel-item">
              <img
                className="trendimg"
                src={image6}
                alt="Final slide with [brief description]"
              />
            </div>
          </div>
          <div>
            <a
              className="carousel-control-prev"
              href="#carousel-1"
              role="button"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon"></span>
              <span className="visually-hidden">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carousel-1"
              role="button"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon"></span>
              <span className="visually-hidden">Next</span>
            </a>
          </div>
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carousel-1"
              data-bs-slide-to="0"
              className="active"
            ></button>{" "}
            <button
              type="button"
              data-bs-target="#carousel-1"
              data-bs-slide-to="1"
            ></button>{" "}
            <button
              type="button"
              data-bs-target="#carousel-1"
              data-bs-slide-to="2"
            ></button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Trending;
